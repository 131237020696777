.main-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 50px;
  background-color: white;
  opacity: 10000;
}

.upper-form-content {
  display: flex;
  flex-direction: row;
}

.form-input-long {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.form-input-medium {
  width: 50%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.form-input-short {
  width: 130px;
  margin-bottom: 8px;
}

.calendar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.calendar-container {
  flex-direction: column;
  flex-direction: column-reverse;
  gap: 5px;
}

.rdrDateDisplayWrapper {
  background-color: rgb(232 248 252) !important;
}

.time-pickers-content {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.time-picker {
  display: flex;
  flex-direction: column;
  width: 315px;
}

.exception-delivery-text {
  margin-top: 18px;
  color: #0183c7;
}

.checkbox-statement {
  padding-top: 30px;
  padding-bottom: 40px;
  font-weight: 700;
}

.form-img-container-small {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.form-img-container-small img {
  height: 200px;
  width: auto;
}

.delivery-info-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

@media (max-width: 905px) {
  .time-pickers-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .form-img-container-small img {
    display: none;
  }
}

@media (max-width: 450px) {
  .calendar {
    zoom: 0.9;
  }
}
